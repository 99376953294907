<template>
  <div class="box">
    <div class="content">
      <div class="l_nav">
        <ul>
          <li>当前位置：</li>
          <li @click="goIndex"><a href="javascript:;">首页 </a></li>
          <template v-if="params.name">
            <li><span>></span></li>
            <li @click="goBack">
              <a href="javascript:;">{{ params.name }}</a>
            </li>
          </template>
          <li><span>></span></li>
          <li>{{ detailsData.journalisTitle }}</li>
        </ul>
      </div>
      <div class="title">{{ detailsData.journalisTitle }}</div>
      <div class="title_m">
        <span>发布时间：{{ toDate(detailsData.createTime, "yyyy-MM-dd") }}</span>
        <span>信息来源：{{ detailsData.message }}</span>
        <span>作者：{{ detailsData.createBy }}</span>
      </div>
      <div class="ql-editor" v-html="text"></div>
    </div>
  </div>
</template>
<script>
import { websiteListQueryId, getTextarea } from "@/api/template";
export default {
  name: "Details",
  components: {},
  data() {
    return {
      detailsData: "",
      params: "",
      text: "",
    };
  },
  created() {
    this.params = JSON.parse(window.sessionStorage.getItem("detailsValue"));
    this.init();
  },
  methods: {
    init() {
      websiteListQueryId(this.params.id).then((res) => {
        this.detailsData = res.data;
        this.getTextareaFun(res.data.journalisContent);
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    goIndex() {
      this.$router.push("/template-fourth");
    },
    getTextareaFun(journalisContent) {
      let params = {
        journalisContent: journalisContent,
      };
      getTextarea(params).then((res) => {
        this.text = res.data.dataHtml;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  width: 978px;
  min-height: 420px;
  margin: 20px auto;
  padding: 20px;
  background: #fff;
  border: 1px solid #dadada;
  .l_nav {
    height: 40px;
    border-bottom: 1px #61aeef solid;
    color: #000000 !important;
    font-size: 14px;
    display: -webkit-box;
    overflow: hidden; /*超出隐藏*/
    text-overflow: ellipsis; /*隐藏后添加省略号*/
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; //想显示多少行
    ul {
      width: 1200px;
      height: 100%;
      // margin: 20px auto 0;
      li {
        float: left;
        line-height: 40px;
        span {
          padding: 0 10px;
        }
        a {
          color: #000000 !important;
        }
      }
    }
  }
  .title {
    color: #00559c;
    font-size: 18px;
    text-align: center;
    line-height: 60px;
    font-weight: bold;
  }
  .title_m {
    text-align: center;
    line-height: 30px;
    font-size: 12px;
    color: #777;
    padding-bottom: 15px;
    span {
      margin-right: 30px;
    }
  }
  .ql-editor {
    padding: 20px 0;
    line-height: 30px;
  }
}
</style>
<style>
.ql-align-center {
  text-align: center !important;
}
</style>
